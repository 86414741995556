/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { Fragment, useEffect, useState } from 'react';
import './reports.scss';
// import HorizontalScroll from '../HorizontalScroll/HorizontalScroll';
import { BarChart, DashboardLoader, SmallBoxLoader, BubbleLoader, TopicLoader } from '../../Loader/loader';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TableComponent from './TableComponent';
import HistoryPopup from './historyPopup';
import Slider from "react-slick";
import { convertDate, convertMillisecondsToTime, convertToDate, formatAMPM, getCodes, SampleNextArrow, SamplePrevArrow } from "../../utils/miscelleneous";
import CircularProgressBar from './circularProgressBar';
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import PopoverHistory from "../../utils/popoverHistory";
import EllipsisText from "react-ellipsis-text";
import ProgressBar from '../../utils/progressBar';
import Drawer from "@mui/material/Drawer";
import exportFromJSON from "export-from-json";
import { Modal, Table } from 'react-bootstrap';
import Overlay from 'react-bootstrap/Overlay';
import { useDispatch, useSelector } from 'react-redux';
import { getTestReports, changeScore, getAssessmentGraph, changeEnable } from '../../Redux/ActionCreators/reportAction';
import html2pdf from 'html2pdf.js';
import TestReport from './test-report';
import Select, { components } from 'react-select';
import { getDescByTitle } from '../../utils/ability';
import TamannaAbility from '../../utils/tamannaAbility.json';
import Switch from 'react-switch';
import InfiniteScroll from 'react-infinite-scroll-component';

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
}
const filters = [
    {
        value: 'name', label: 'Student Name'
    },
    {
        value: 'total_engagement', label: 'Engagement Score'
    }
]

export default function IndividualSection(props) {
    const [activeTab, setActiveTab] = useState('school');
    const [scorePage, setScorePage] = useState(1);
    const [activeBtn, setActiveBtn] = useState('section');
    const [open, setOpen] = useState(false);
    const [chapterQuery, setchapterQuery] = useState('');
    const [sectionChapterQuery, setSectionChapterQuery] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [isHistory, setIsHistory] = useState(false);
    const [activeStatus, setActiveStatus] = useState('chapters');
    const [isOpenDetails, setOpenDetails] = useState(false);
    const [testDetail, setTestDetail] = useState('');
    const [loading, setLoading] = useState(false);
    const [isDetails, setIsDetails] = useState(false);
    const [targetMenu, setTargetMenu] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [menu, setMenu] = useState('');
    const [stuLoading, setStuLoading] = useState(false);
    const [chapter, setChapter] = useState('');
    const [list, setList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [details, setDetails] = useState(null);
    const [qLoading, setQLoading] = useState(false);
    const [isReport, setIsReport] = useState(false);
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [sectionId, setSectionId] = useState('');
    const [section, setSection] = useState('');
    const [current, setCurrent] = useState('');
    const [query, setQuery] = useState('');
    const [sort, setSort] = useState({ label: 'Engagement Score', value: 'total_engagement' });
    const [page, setPage] = useState(1);
    const [cls, setClass] = useState('');
    const [testPage, setTestPage] = useState(1);
    const [isListView, setIsListView] = useState(false);
    const [subjectId, setSubjectId] = useState('');
    const [classId, setClassId] = useState('')
    const sliderRef = React.useRef(null);
    const timerRef1 = React.useRef(null);


    const dispatch = useDispatch();
    const { abilityQuestions, ability, scores, enableReport } = useSelector(state => state.report);
    useEffect(() => {
        setActiveTab(props.activeTab);
        activeTab === 'school' && props.getClasses();
    }, [])

    const onChangeStatus = async (e) => {
        setActiveStatus(e);
        await props.onResetSubject();
        if (e === 'chapters') {
            await props.getTopicsForStudent(props.centerObj && props.centerObj?._id, props.activeSubId);
        }
        else {
            await props.getTestList(props.activeSubId, props.centerObj && props.centerObj?._id);
        }
    }




    const onBtnChange = (e) => {
        setActiveBtn(e);
        setchapterQuery('');
        setSectionChapterQuery('');
        setLoading(true);
        props.refreshChapterData();
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }


    const nextPage = () => {
        setPage(page + 1);
        props.getSchoolStudents(props.centerObj?._id, 'student', page + 1, sort?.value, query, '');
    }

    const prevPage = () => {
        setPage(page - 1);
        props.getSchoolStudents(props.centerObj?._id, 'student', page - 1, sort?.value, query, '');
    }


    const searchChapter = (val) => {
        setchapterQuery(val);
        props.searchChapterResult(val);
    }

    const searchSectionChapter = (val) => {
        setSectionChapterQuery(val);
        props.searchSectionChapterResult(val);
    }



    const onSubjectChange = async (title, id) => {
        await props.onResetSubject();
        await props.changeSubject(title, id);
        if (activeStatus === 'chapters') {
            await props.getTopicsForStudent(props.centerObj && props.centerObj?._id, id);
        }
        else {
            await props.getTestList(id, props.centerObj && props.centerObj?._id);
        }
    }

    const getSubtopics = async (index, subid, userid, details) => {
        console.log(details)
        if (chapter === details?.name) {
            setChapter('');
        } else {
            setChapter(details?.name);
            await props.resetSubtopicsData();
            await props.getSubtopics(index, subid, userid);
        }
    }

    const onExitPopover = () => {
        document.body.click();
    };

    const getScoreHistory = () => {
        setIsHistory(true);
        setScorePage(1);
        props.getScoresHistory(props.centerObj && props.centerObj?._id, scorePage);
    }


    const fetchData = () => {
        setScorePage(scorePage + 1);
        props.getNextScoresHistory(props.centerObj && props.centerObj?._id, scorePage + 1);
    }


    const openDetails = (testDetail) => {
        setOpenDetails(true);
        setTestDetail(testDetail);
    };

    const checkFocus = () => {
        // dummy element
        var dummyEl = document.getElementById("express-form-typeahead");
        // check for focus
        var isFocused = document.activeElement === dummyEl;
        if (isFocused) {
            setIsFocused(isFocused)
        } else {
            setIsFocused(false);
        }
    };

    const downloadData = async (data1, type) => {
        if (type === 'chapter') {
            const data = data1.map(
                (el) => [
                    {
                        'Chapter Name': el.title,
                        'No of Students': el.students,
                        'less_than_35': el.less_than_35,
                        'range_35_to_49': el.range_35_to_49,
                        'range_50_to_89': el.range_50_to_89,
                        'range_90_to_100': el.range_90_to_100
                    },
                ][0]
            );
            const fileName = "chapter_data";
            const exportType = await exportFromJSON.types.csv;
            await exportFromJSON({ data, fileName, exportType });
        } else {
            console.log(data1)
            const data = data1.map(
                (el) => [
                    {
                        'Section Name': el.title,
                        'No of Students': el.students,
                        'less_than_35': el.less_than_35,
                        'range_35_to_49': el.range_35_to_49,
                        'range_50_to_89': el.range_50_to_89,
                        'range_90_to_100': el.range_90_to_100
                    },
                ][0]
            );
            const fileName = "section_chapter_data";
            const exportType = await exportFromJSON.types.csv;
            await exportFromJSON({ data, fileName, exportType });
        }
    }

    const openModal = (type, data) => {
        console.log(data)
        setIsDetails(true);
        const details = JSON.parse(localStorage.getItem('subDetails'))
        props.getSectionChapterDetails(details.subject_id, details.course_id, data?.section_id);
    }

    const handleChange = (value) => {
        clearTimeout(timerRef1.current);
        setQuery(value);
        setPage(1);
        timerRef1.current = setTimeout(() => getStudentQuery(value), 800);
    }

    const getStudentQuery = (val) => {
        props.getSchoolStudents(centerObj?._id, 'student', page, sort?.value, val, sectionId);
    }

    const openFilter = () => {
        setIsOpenFilter(true);
        // setCourse('');
        setSection('');
        setSectionId('');
        setClassId('');
        setClass('');
    }


    const selectClass = async (e) => {
        setClass(e);
        setClassId(e.value);
        setSection(null);
        setSectionId('');
        await props.getSectionFromClass(e.class, e.affiliation[0]?._id);
    }

    const selectSection = (e) => {
        setSection(e);
        setSectionId(e.value);
    }

    const applyFilter = async () => {
        setIsOpenFilter(false);
        setQuery('');
        setPage(1);
        props.getSchoolStudents(centerObj?._id, 'student', 1, sort?.value, query, sectionId);
    }


    const getSortStudent = async (e) => {
        setSort(e);
        setPage(1);
        props.getSchoolStudents(centerObj?._id, 'student', 1, e?.value, query, sectionId);
    }



    const currentPage = async () => {
        // setPage(Number(current))
        // await props.resetStudentLoading();
        // await props.getListOfStudent(Number(current), query, courseId, sectionId, sort.value, 'student');
    }



    const { centerObj, schoolStudentList, totalPages, studentTrend, studentHead, schoolLoginDates, schoolLoginTrends,
        courseGraph, courseEngagement, semesterData, semesterHead, engagementTrend,
        dataLoading, dataLoading1, dataLoading2, scoreDetails, studentTren, subjectWiseGraph, monthlyWise, semsforTest, semLegands, engagementScoreGraph, chapterHead, chapterData, sectionChapterData, subjectSectionGraph, chapterSectionHead,
        facultySubjectData, facultyTren, facultySubjectHead, contentCount, scoreHistory, subject, subjectComplete, testComplete, subjectCount, testCount, activeSub, subjectTopics, chapterLoading, isSubtopicLoading, testList, quizHistory,
        abilities, studentId, assessmentReports, testReport, activeType, classSubjectHead, classWiseSubjectData, practiceTestList, testsLoading, ishLoading, testHistory } = props

    var settings = {
        dots: false,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 3,
        nextArrow: <SampleNextArrow className={"next-arrow"} />,
        prevArrow: <SamplePrevArrow className={"prev-arrow"} />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 1008,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    if (activeTab === 'student') {
        var percent = studentTren !== undefined && studentTren.filter(el => el.login !== 0)
        var percentage = Math.round((percent?.length / (studentTren !== undefined && studentTren.length)) * 100);
    }

    if (activeTab === 'adminfaculty') {
        var percent1 = facultyTren !== undefined && facultyTren.filter(el => el.login !== 0)
        var percentage1 = Math.round((percent1?.length / (facultyTren !== undefined && facultyTren.length)) * 100);
    }
    const downloadChapterdata = async (data1) => {
        const data = data1.map(
            (el) => [
                {
                    subject: el.subject_title,
                    section: el.section_title,
                    'Chapter Name': el.title,
                    'No of Students': el.students,
                    'less_than_35': el.less_than_35,
                    'range_35_to_49': el.range_35_to_49,
                    'range_50_to_89': el.range_50_to_89,
                    'range_90_to_100': el.range_90_to_100,

                },
            ][0]
        );
        const fileName = "section_chapter_data";
        const exportType = await exportFromJSON.types.csv;
        await exportFromJSON({ data, fileName, exportType });

    }

    const openMenus = async (e, type, data) => {
        e.preventDefault();
        e.stopPropagation();
        await setStuLoading(true);
        await setList(data);
        await setTargetMenu(e.target);
        await setMenu(type);
        await setOpenMenu(!openMenu);
        await setStuLoading(false);
    }

    const getQuestions = async (el) => {
        console.log(el)
        setIsOpen(true);
        setDetails(el);
        await setQLoading(true);
        await dispatch(getTestReports(el._id, el.test_id, studentId));
        await setQLoading(false);
    }

    const openReport = async (id) => {
        setQLoading(true);
        await props.getOneReports(studentId, id);
        setIsReport(true);
        setQLoading(false);

    }

    const downloadPdf = async () => {
        await setQLoading(true);
        const input = document.getElementById('assess-report');
        var opt = {
            format: 'A4',
            orientation: 'portait', // Can also be 'landscape'

            margin: [0.5, 0.05, 0.5, 0.05],
            filename: `${centerObj?.name}-${props.activeType?.name}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                scale: 1,
                useCORS: true, // Enable this for cross-origin images
                logging: true
            },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        // New Promise-based usage:
        html2pdf().from(input).set(opt).save();
        await setQLoading(false)
    }

    const getTests = async (page, sid) => {
        await setIsListView(true);
        await setSubjectId(sid);
        await props.getPracticeTest(page, sid);
        console.log(isListView)
    }

    const fetchTestData = () => {
        setTestPage(testPage + 1);
        props.getNextPracticeTest(testPage + 1, subjectId)
    }


    const getTestsHistory = async (id, question) => {
        console.log(id, question)
        setIsHistory(true);
        await props.resetHistoryLoading();
        await props.getTestHistory(id, question);
    }


    //assesment tab graph
    const abilityTrend = {
        chart: {
            width: 600,
            type: 'line',
            height: 400
        },
        title: {
            text: 'Ability Trends'
        },
        subtitle: {
            // text: '3,456 Students'
        },
        xAxis: {
            categories: ability !== undefined && ability,
            visible: true,
            gridLineWidth: 1
        },
        colors: ['#ECC30B'],
        yAxis: {
            visible: true,
            min: 0,
            gridLineWidth: 1,
            max: 10,
            tickInterval: 1,
            title: {
                text: 'Sten Score'
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                },
                showInLegend: false,
                enableMouseTracking: false
            }
        },
        series: [{
            name: 'Trend',
            data: scores && scores
        },
        ]
    }

    const scoreArr = Array.from({ length: 71 }, (_, i) => i)
    console.log(activeTab)
    return (
        <div id='assess-report'>
            {centerObj !== undefined &&
                <Fragment>
                    {
                        activeTab === 'assessment' ?
                            <div className='d-flex justify-content-between' style={{ width: '88%' }}>
                                <h3 className='d-flex align-items-start cursor-pointer'><img src='/images/back.svg' alt='' onClick={() => !isReport ? props.backToMain() : activeType?.name === 'riasec' ? setIsReport(false) : props.backToMain()} style={{ marginTop: window.innerWidth > 767 ? 5 : 7 }} />&nbsp; <span>{centerObj.name || centerObj.title || centerObj.program} : {activeTab === 'assessment' ? 'Assessment Data' : 'Engagement Data'}</span>
                                </h3>
                                <div className='d-flex align-items-center'>
                                    Enable Report &nbsp;&nbsp;
                                    <div className='' onClick={(e) => { dispatch(changeEnable(activeType?.name, centerObj?._id, activeType?.name === 'riasec' ? assessmentReports[0]?._id : abilities[0]?.test_id, enableReport)) }}>
                                        <Switch
                                            checked={enableReport}
                                            width={30}
                                            height={16}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            onColor="#00B24B"
                                            onChange={(e) => console.log(e)}
                                        />
                                    </div>
                                </div>
                                {(activeType !== 'riasec' && ability?.length) ?
                                    <div className='d-flex justify-content-center align-items-center cursor-pointer' disabled={qLoading} onClick={downloadPdf} style={{ marginTop: window.innerWidth > 767 ? 5 : 7 }} ><img src='/images/download.svg' width={20} alt='' />&nbsp; <span >Download Report</span>
                                    </div>
                                    : null
                                }
                            </div> :
                            !props.isShowChapter ?
                                <div className='d-flex justify-content-between' style={{ width: '88%' }}>
                                    <h3 className='d-flex align-items-start cursor-pointer'><img src='/images/back.svg' alt='' onClick={() => isListView ? setIsListView(false) : props.backToMain()} style={{ marginTop: window.innerWidth > 767 ? 5 : 7 }} />&nbsp; <span>{centerObj.name || centerObj.title || centerObj.program || centerObj.class} : {activeTab === 'test' ? 'Practice Test Data' : activeTab === 'assessment' ? 'Assessment Data' : 'Engagement Data'}</span>
                                    </h3>
                                    {ability?.length ?
                                        <div className='d-flex justify-content-center align-items-center cursor-pointer' disabled={qLoading} onClick={downloadPdf} style={{ marginTop: window.innerWidth > 767 ? 5 : 7 }} ><img src='/images/download.svg' width={20} alt='' />&nbsp; <span >Download Report</span>
                                        </div>
                                        : null
                                    }
                                </div>
                                :

                                <h3 className='d-flex align-items-start cursor-pointer'><img src='/images/back.svg' alt='' onClick={() => {
                                    setActiveBtn('chapter');
                                    props.isHideChapter()
                                }
                                } style={{ marginTop: window.innerWidth > 767 ? 5 : 7 }} />&nbsp; <span>{centerObj.name || centerObj.title || centerObj.program} :{'Engagement Data'}</span>
                                </h3>
                    }

                    <p className='program-title'>{activeTab === 'subject' && centerObj.program && centerObj.program}</p>
                    {
                        activeTab === 'school' ?
                            <div className='center-reports mt-2'>
                                {
                                    <div className='program-graph '>
                                        {
                                            schoolLoginDates && schoolLoginTrends
                                                ?
                                                <div className='row ml-0 mr-0'>
                                                    <div className='col-md-6'>
                                                        <HighchartsReact
                                                            highcharts={Highcharts}
                                                            options={studentTrend}
                                                        />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <HighchartsReact
                                                            highcharts={Highcharts}
                                                            options={engagementTrend}
                                                        />
                                                    </div>

                                                </div> :
                                                <div className='text-center mt-4'>
                                                    <BarChart />
                                                </div>
                                        }

                                    </div>
                                }
                                <div className='reports-header  '>
                                    <div className='d-flex justify-content-between align-items-center header-width'>
                                        <h3>Student Engagement Detailed Data  </h3>
                                        <div className='d-flex align-items-center cursor-pointer' onClick={openFilter}>
                                            <img src='/images/filter-icon.svg' alt='' /> &nbsp;<p>Filters</p>
                                        </div>
                                        <div className='d-flex align-items-center cursor-pointer' style={{ width: window.innerWidth > 767 ? '35%' : '70%', }} >
                                            <p style={{ width: '34%', }}>Sort by</p>
                                            <div style={{ width: '100%', zIndex: window.innerWidth > 767 ? 1 : 6 }} >
                                                <Select
                                                    components={{ DropdownIndicator }}
                                                    options={filters}
                                                    onChange={getSortStudent}
                                                    placeholder='Select'
                                                    value={sort}
                                                />
                                            </div>
                                        </div>
                                        {
                                            window.innerWidth > 767 ? null :
                                                <div className='ml-4' onClick={() => {
                                                    setOpen(!open);
                                                    setQuery(!open ? '' : query)
                                                }}>
                                                    <img src='/images/search.svg' alt='' />
                                                </div>
                                        }
                                    </div>
                                    {
                                        window.innerWidth > 767 ?
                                            <div class="animated-search" style={{ top: 24 }}>
                                                <input
                                                    id="express-form-typeahead"
                                                    autoComplete="off"
                                                    onBlur={checkFocus}
                                                    onFocus={checkFocus}
                                                    spellCheck="false"
                                                    type="text"
                                                    value={query}
                                                    onChange={(e) => handleChange(e.target.value)}
                                                />
                                                <div class="symbol">
                                                    <img src="/images/search.svg" alt="" />
                                                </div>
                                                {isFocused ? (
                                                    <img
                                                        src="/images/close2.png"
                                                        alt=""
                                                        className="close-input"
                                                        onClick={() => setIsFocused(false)}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </div> : null

                                    }
                                    {
                                        open ?
                                            <input name='search' type='search' autoFocus className='form-control mt-2' autoComplete='off' value={query} onChange={(e) => handleChange(e.target.value)} placeholder='Search' />
                                            : null
                                    }
                                </div>
                                <div className='center-table '>
                                    {

                                        <TableComponent
                                            header={studentHead}
                                            centerList={schoolStudentList}
                                            type='schoolStudent'
                                        />
                                    }
                                </div>
                                {
                                    !props.isStudentLoading &&
                                    totalPages > 1 &&
                                    <div className='user-pagination'>
                                        {
                                            page === 1 ?
                                                <img src='/images/page_prev_disable.svg' alt='' />
                                                : <img src='/images/page_prev_active.svg' onClick={prevPage} alt='' />
                                        }
                                        &nbsp;&nbsp;
                                        <div>Page&nbsp;&nbsp; <input value={page} readOnly disabled />&nbsp;of {totalPages}</div>&nbsp;
                                        {
                                            page === totalPages ?
                                                <img src='/images/page_next_disable.svg' alt='' /> :
                                                <img src='/images/page_next_active.svg' onClick={nextPage} alt='' />
                                        }&nbsp;&nbsp;
                                        Go to page &nbsp;<input value={current} className='iput' onChange={(e) => setCurrent(e.target.value)} />&nbsp; <button style={{ pointerEvents: current > totalPages ? 'none' : 'auto' }} onClick={current > totalPages ? '' : currentPage} className='go-btn'>Go</button>
                                    </div>
                                }
                            </div>
                            :
                            activeTab === 'course' ?
                                <div className='center-reports mt-2'>
                                    <div className='program-graph '>
                                        {
                                            props.show ?
                                                <Fragment>
                                                    <div className='row ml-0 mr-0'>
                                                        <div className='col-md-6'>
                                                            {props.show &&
                                                                <HighchartsReact
                                                                    highcharts={Highcharts}
                                                                    options={courseGraph}
                                                                />}
                                                        </div>
                                                        <div className='col-md-6'>
                                                            {props.show &&
                                                                <HighchartsReact
                                                                    highcharts={Highcharts}
                                                                    options={courseEngagement}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='row ml-0 mr-0'>
                                                        <div className='program-legand mt-3' >
                                                            {
                                                                semLegands !== undefined && semLegands.map((el, i) => {
                                                                    return (
                                                                        window.innerWidth > 767 ?
                                                                            <Fragment>
                                                                                <div style={{ background: el.color }} className='legand'></div> &nbsp; &nbsp;<p>{el.name}</p>
                                                                            </Fragment>
                                                                            :
                                                                            <div className='col-3 d-flex'>
                                                                                <div style={{ background: el.color }} className='legand'></div> &nbsp; &nbsp;<p>{el.name}</p>
                                                                            </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </Fragment>
                                                :
                                                <div className='text-center mt-5'>
                                                    <BarChart />
                                                </div>
                                        }

                                    </div>
                                    <div className='reports-header  '>
                                        <div className='d-flex justify-content-between align-items-center header-width' >
                                            <h3>Semester Engagement Detailed Data  </h3>


                                        </div>
                                    </div>
                                    <div className='center-table '>
                                        <TableComponent
                                            header={semesterHead}
                                            centerList={semesterData}
                                            type='semester'
                                            sortProgramSemList={props.sortProgramSemList}
                                        />
                                    </div>
                                </div>
                                :
                                activeTab === 'subject' ?
                                    <div className='center-reports mt-2'>
                                        <div className='center-btns'>
                                            <button className={activeBtn === 'section' ? "active-btn" : null} onClick={() => onBtnChange('section')}>Section View</button>&nbsp; &nbsp;
                                            <button className={activeBtn === 'chapter' ? "active-btn" : null} onClick={() => onBtnChange('chapter')}>Chapter View</button>
                                        </div>
                                        {loading ?
                                            <TopicLoader /> :
                                            activeBtn === 'chapter' ?
                                                <Fragment>
                                                    {/* <div className='center-graph'>
                                                            <div className='d-flex justify-content-between'>
                                                                <h1>Top 10 Chapterwise Completion</h1>
                                                                
                                                                <div className='legands'>
                                                                    <div className='legand-1'>
                                                                        <div></div>&nbsp; <p>Average Completion %</p>
                                                                    </div>
                                                                    <div className='legand-1 mt-2'>
                                                                        <div style={{ background: '#ECC30B' }}></div>&nbsp; <p>Student Count</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                props.show ?
                                                                    <HighchartsReact
                                                                        highcharts={Highcharts}
                                                                        options={subjectGraph}
                                                                    /> :
                                                                    <div className='text-center mt-5'>
                                                                        <BarChart />
                                                                    </div>
                                                            }

                                                        </div> */}
                                                    <div className='reports-header  '>
                                                        <div className='d-flex justify-content-between align-items-center header-width'>
                                                            <h3>Chapter Completion Details&nbsp;&nbsp;
                                                                {chapterData?.length > 0 ? <img src='/images/download.svg' onClick={() => downloadData(chapterData, 'chapter')} className='cursor-pointer' width={20} alt='' /> : null}
                                                            </h3>
                                                            {/* <div className='d-flex align-items-center cursor-pointer' style={{}}>
                                                                    <img src='/images/filter-icon.svg' alt='' /> &nbsp;<p>Filters</p>
                                                                </div> */}
                                                            {
                                                                window.innerWidth > 767 ? null :
                                                                    <div className='ml-4' onClick={() => {
                                                                        setOpen(!open);
                                                                        setchapterQuery(!open ? '' : chapterQuery);
                                                                    }}>
                                                                        <img src='/images/search.svg' alt='' />
                                                                    </div>
                                                            }
                                                        </div>
                                                        {
                                                            window.innerWidth > 767 ?
                                                                <div class="animated-search" style={{ display: chapterData?.length > 2 ? 'block' : 'none', top: 18 }}>
                                                                    <input
                                                                        id="express-form-typeahead"
                                                                        autoComplete="off"
                                                                        onBlur={checkFocus}
                                                                        onFocus={checkFocus}
                                                                        spellCheck="false"
                                                                        type="text"
                                                                        onChange={(e) => searchChapter(e.target.value)}
                                                                    />
                                                                    <div class="symbol">
                                                                        <img src="/images/search.svg" alt="" />
                                                                    </div>
                                                                    {isFocused ? (
                                                                        <img
                                                                            src="/images/close2.png"
                                                                            alt=""
                                                                            className="close-input"
                                                                            onClick={() => setIsFocused(false)}
                                                                        />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div> : null

                                                        }
                                                        {
                                                            open ?
                                                                <input name='search' type='search' className='form-control mt-1' autoFocus autoComplete='off' value={chapterQuery} onChange={(e) => searchChapter(e.target.value)} placeholder='Search' />
                                                                : null
                                                        }
                                                    </div>
                                                    <div className='center-table '>
                                                        <TableComponent
                                                            header={chapterHead}
                                                            centerList={chapterData}
                                                            type='subject1'
                                                        />
                                                    </div>
                                                </Fragment>
                                                :

                                                <Fragment>
                                                    {/* <div className='center-graph'>
                                                            <div className='d-flex justify-content-between'>
                                                                <h1>Sectionwise Completion</h1>
                                                        
                                                                <div className='legands'>
                                                                    <div className='legand-1'>
                                                                        <div></div>&nbsp; <p>Average Completion %</p>
                                                                    </div>
                                                                    <div className='legand-1 mt-2'>
                                                                        <div style={{ background: '#ECC30B' }}></div>&nbsp; <p>Student Count</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {loading ?
                                                                <div className='text-center mt-2'>
                                                                    <BarChart />
                                                                </div>
                                                                :
                                                                <HighchartsReact
                                                                    highcharts={Highcharts}
                                                                    options={subjectSectionGraph}
                                                                />
                                                            }
                                                        </div> */}
                                                    <div className='reports-header  '>
                                                        <div className='d-flex justify-content-between align-items-center header-width'>
                                                            <h3>Section Chapters Completion Details
                                                                &nbsp;&nbsp;
                                                                {sectionChapterData?.length > 0 ? <img src='/images/download.svg' onClick={() => downloadData(sectionChapterData, 'sectionchapter')} className='cursor-pointer' width={20} alt='' /> : null} </h3>
                                                            {/* <div className='d-flex align-items-center cursor-pointer' style={{}}>
                                                                    <img src='/images/filter-icon.svg' alt='' /> &nbsp;<p>Filters</p>
                                                                </div> */}
                                                            {
                                                                window.innerWidth > 767 ? null :
                                                                    <div className='ml-4' onClick={() => {
                                                                        setOpen(!open);
                                                                        setSectionChapterQuery(!open ? '' : sectionChapterQuery);
                                                                    }}>
                                                                        <img src='/images/search.svg' alt='' />
                                                                    </div>
                                                            }
                                                        </div>
                                                        {
                                                            window.innerWidth > 767 ?
                                                                <div class="animated-search" style={{ display: sectionChapterData?.length > 2 ? 'block' : 'none', top: 18 }}>
                                                                    <input
                                                                        id="express-form-typeahead"
                                                                        autoComplete="off"
                                                                        onBlur={checkFocus}
                                                                        onFocus={checkFocus}
                                                                        spellCheck="false"
                                                                        type="text"
                                                                        onChange={(e) => searchSectionChapter(e.target.value)}
                                                                    />
                                                                    <div class="symbol">
                                                                        <img src="/images/search.svg" alt="" />
                                                                    </div>
                                                                    {isFocused ? (
                                                                        <img
                                                                            src="/images/close2.png"
                                                                            alt=""
                                                                            className="close-input"
                                                                            onClick={() => setIsFocused(false)}
                                                                        />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div> : null

                                                        }
                                                        {
                                                            open ?
                                                                <input name='search' type='search' className='form-control mt-1' autoFocus autoComplete='off' value={sectionChapterQuery} onChange={(e) => searchSectionChapter(e.target.value)} placeholder='Search' />
                                                                : null
                                                        }
                                                    </div>
                                                    <div className='center-table '>
                                                        <TableComponent
                                                            header={chapterSectionHead}
                                                            centerList={sectionChapterData}
                                                            openIndividualItem={openModal}
                                                            type='subject2'
                                                        />
                                                    </div>
                                                </Fragment>
                                        }
                                    </div> :
                                    activeTab === 'adminfaculty' ?
                                        <div className='center-reports mt-2'>
                                            {!props.isShowChapter ?
                                                <Fragment>
                                                    {/* <div className='content-consume mt-2' style={{ width: '103%' }}>
                                                            <div className="row w-100">
                                                                <div className="col-lg-6 col-sm-6" style={{ paddingRight: window.innerWidth > 767 ? 10 : 0 }}>
                                                                    <div className='subject-consume'>
                                                                        <div className='legand-box'>
                                                                            <h3>Last 10 days login trend
                                                                            </h3>
                                                                            <div className={window.innerWidth > 767 ? 'legend-box d-flex' : 'legend-box d-flex w-100'}>
                                                                                <div className='legend px-3 d-flex align-items-center'>
                                                                                    <div className='legend-circle' style={{ background: '#00B24B' }}></div>&nbsp; LoggedIn
                                                                                </div>
                                                                                <div className='legend px-3 d-flex align-items-center'>
                                                                                    <div className='legend-circle' style={{ background: '#FF5A5F' }}></div>&nbsp; Absent
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={props.show ? 'student-trend row' : 'student-trend row justify-content-center'}>
                                                                            {
                                                                                props.show ?
                                                                                    <Fragment>
                                                                                        <div className='col-md-3 mt-4 login-percent'>
                                                                                            <h4>{percentage1 + '%'}</h4>
                                                                                            <span>{percent1 && percent1.length}/10 Days</span>
                                                                                        </div>
                                                                                        <div className='col-md-9 d-flex flex-wrap justify-content-center'>
                                                                                            {
                                                                                                facultyTren !== undefined && facultyTren.map(el => {
                                                                                                    return (
                                                                                                        <div className='circle mt-3' style={{ background: el.login === false ? '#FF5A5F' : '#00B24B' }}>
                                                                                                            {el.date}
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </Fragment> :
                                                                                    <div className='text-center mt-0'>
                                                                                        <BarChart />
                                                                                    </div>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-6 col-sm-6 pl-md-0 pl1 " style={{ marginTop: window.innerWidth > 767 ? 0 : 8 }}>

                                                                    <div className="engagement-graph">
                                                                        <h3>Engagement score
                                                                            {
                                                                                localStorage.getItem('role').includes('master') ?
                                                                                    <small onClick={() => getScoreHistory()}><img src='/images/history1.svg' alt='' /> &nbsp;Engagement history </small>
                                                                                    : null
                                                                            }
                                                                        </h3>
                                                                        <div className={window.innerWidth > 767 ? 'd-flex justify-content-center align-items-center row' : 'd-flex justify-content-center align-items-center row'}>
                                                                            {props.show ?
                                                                                <Fragment>
                                                                                    <div className='engagement-score col-md-4' >
                                                                                        <h3>{props.engagement_score}</h3>
                                                                                        <p>Engagement Score</p>
                                                                                    </div>
                                                                                    <div className='engagement-chart col-md-8' style={{ height: '40vh' }}>
                                                                                        {
                                                                                            props.scores !== undefined && props.scores.every(element => element === 0) ?
                                                                                                <h4 className="engagement-update">
                                                                                                    Your data will be available after 24
                                                                                                    hours</h4> :
                                                                                                <div>
                                                                                                    <HighchartsReact
                                                                                                        highcharts={Highcharts}
                                                                                                        options={engagementScoreGraph}
                                                                                                    />
                                                                                                </div>
                                                                                        }
                                                                                    </div>
                                                                                </Fragment>
                                                                                :
                                                                                <div className='text-center mt-4'>
                                                                                    <BarChart />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    {/* <div className='center-graph mt-2'>
                                                            <div className='d-flex justify-content-between'>
                                                                <h1>Subject Completion View</h1>
                                                                <div className='legands'>
                                                                    <div className='legand-1'>
                                                                        <div></div>&nbsp; <p>Engagement Score</p>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <HighchartsReact
                                                                highcharts={Highcharts}
                                                                options={subjectGraph}
                                                            />
                                                        </div> */}
                                                    <div className='reports-header  '>
                                                        <div className='d-flex justify-content-between align-items-center header-width' style={{ width: '100%' }}>
                                                            <h3 style={{ width: '100%' }}>Content Added Data
                                                            </h3>
                                                            <div className='d-flex align-items-center cursor-pointer' style={{}}>
                                                                {/* <img src='/images/filter-icon.svg' alt='' /> &nbsp;<p>Filters</p> */}
                                                            </div>
                                                        </div>
                                                        {props.countLoading ?
                                                            '' :
                                                            <div className='d-flex  align-items-center flex-wrap mt-2'>
                                                                {
                                                                    <div className='content-1'>
                                                                        <img src='/images/video-icon.svg' alt='' />
                                                                        <h2>{contentCount.video_count}<p>{contentCount.video_count > 1 ? 'Videos' : 'Video'}</p></h2>
                                                                    </div>
                                                                }
                                                                {
                                                                    <div className='content-1'>
                                                                        <img src='/images/article-icon.svg' alt='' />
                                                                        <h2>{contentCount.article_count} <p>{contentCount.article_count > 1 ? 'Articles' : 'Article'}</p></h2>
                                                                    </div>

                                                                }
                                                                {
                                                                    <div className='content-1'>
                                                                        <img src='/images/document-icon.svg' alt='' />
                                                                        <h2>{contentCount.document_count} <p>{contentCount.document_count > 1 ? 'Documents' : 'Document'}</p></h2>
                                                                    </div>
                                                                }
                                                                {
                                                                    <div className='content-1'>
                                                                        <img src='/images/quiz.svg' alt='' />
                                                                        <h2>{contentCount.question_count}<p>{contentCount.question_count > 1 ? 'Questions' : 'Question'}</p></h2>
                                                                    </div>

                                                                }

                                                            </div>
                                                        }
                                                        {!props.countLoading && <h3 className='mt-3'>Subject Engagement Detailed Data</h3>}
                                                    </div>
                                                    <div className='center-table '>
                                                        <TableComponent
                                                            header={facultySubjectHead}
                                                            centerList={facultySubjectData}
                                                            type='adminsubject'
                                                            openIndividualItem={props.openIndividualItem}
                                                            sortFacultySubjectList={props.sortFacultySubjectList}
                                                            getTopChapterCompletion={props.getTopChapterCompletion}
                                                            getAllChapterCompletion={props.getAllChapterCompletion}
                                                            getTopSectionChapterCompletion={props.getTopSectionChapterCompletion}
                                                            getAllSectionChapterCompletion={props.getAllSectionChapterCompletion}
                                                            setShow={props.setShow}
                                                            resetFacultySubjectChapter={props.resetFacultySubjectChapter}
                                                        />
                                                    </div>
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <div className='center-reports mt-2'>
                                                        <div className='center-btns'>
                                                            <button className={activeBtn === 'chapter' ? "active-btn" : null} onClick={() => onBtnChange('chapter')}>Chapter View</button> &nbsp; &nbsp;
                                                            <button className={activeBtn === 'section' ? "active-btn" : null} onClick={() => onBtnChange('section')}>Section View</button>
                                                        </div>
                                                        {
                                                            activeBtn === 'chapter' ?
                                                                <Fragment>
                                                                    {/* <div className='center-graph'>
                                                                            <div className='d-flex justify-content-between'>
                                                                                <h1>Top 10 Chapterwise Completion</h1>
                                                                                
                                                                                <div className='legands'>
                                                                                    <div className='legand-1'>
                                                                                        <div></div>&nbsp; <p>Average Completion %</p>
                                                                                    </div>
                                                                                    <div className='legand-1 mt-2'>
                                                                                        <div style={{ background: '#ECC30B' }}></div>&nbsp; <p>Student Count</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <HighchartsReact
                                                                                highcharts={Highcharts}
                                                                                options={subjectGraph}
                                                                            />
                                                                        </div> */}
                                                                    <div className='reports-header  '>
                                                                        <div className='d-flex justify-content-between align-items-center header-width'>
                                                                            <h3>Chapter Completion Details </h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className='center-table '>
                                                                        <TableComponent
                                                                            header={chapterHead}
                                                                            centerList={chapterData}
                                                                            type='subject1'

                                                                        />
                                                                    </div>
                                                                </Fragment>
                                                                :
                                                                <Fragment>
                                                                    {/* <div className='center-graph'>
                                                                            <div className='d-flex justify-content-between'>
                                                                                <h1>Sectionwise Completion</h1>
                                                                                <div className='legands'>
                                                                                    <div className='legand-1'>
                                                                                        <div></div>&nbsp; <p>Average Completion %</p>
                                                                                    </div>
                                                                                    <div className='legand-1 mt-2'>
                                                                                        <div style={{ background: '#ECC30B' }}></div>&nbsp; <p>Student Count</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <HighchartsReact
                                                                                highcharts={Highcharts}
                                                                                options={subjectGraph}
                                                                            />
                                                                        </div> */}
                                                                    <div className='reports-header  '>
                                                                        <div className='d-flex justify-content-between align-items-center header-width'>
                                                                            <h3>Sectionwise Completion Details </h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className='center-table '>
                                                                        <TableComponent
                                                                            header={chapterSectionHead}
                                                                            centerList={sectionChapterData}
                                                                            type='subject1'
                                                                        />
                                                                    </div>
                                                                </Fragment>
                                                        }
                                                    </div>
                                                </Fragment>
                                            }
                                        </div>
                                        :
                                        activeTab === 'faculty' ?
                                            <div className='center-reports mt-2'>
                                                <div className='center-btns'>
                                                    <button className={activeBtn === 'chapter' ? "active-btn" : null} onClick={() => onBtnChange('chapter')}>Chapter View</button> &nbsp; &nbsp;
                                                    <button className={activeBtn === 'section' ? "active-btn" : null} onClick={() => onBtnChange('section')}>Section View</button>
                                                </div>
                                                {
                                                    activeBtn === 'chapter' ?
                                                        <Fragment>
                                                            {/* <div className='center-graph'>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <h1>Top 10 Chapterwise Completion</h1>
                                                                        <select>All time</select>
                                                                        <div className='legands'>
                                                                            <div className='legand-1'>
                                                                                <div></div>&nbsp; <p>Total Completion %</p>
                                                                            </div>
                                                                            <div className='legand-1 mt-2'>
                                                                                <div style={{ background: '#ECC30B' }}></div>&nbsp; <p>Student Count</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <HighchartsReact
                                                                        highcharts={Highcharts}
                                                                        options={subjectGraph}
                                                                    />
                                                                </div> */}
                                                            <div className='reports-header  '>
                                                                <div className='d-flex justify-content-between align-items-center header-width'>
                                                                    <h3>Chapter Completion Details </h3>
                                                                </div>


                                                            </div>
                                                            <div className='center-table '>
                                                                <TableComponent
                                                                    header={chapterHead}
                                                                    centerList={chapterData}
                                                                    type='subject1'
                                                                />
                                                            </div>
                                                        </Fragment>
                                                        :
                                                        <Fragment>
                                                            {/* <div className='center-graph'>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <h1>Sectionwise Completion</h1>
                                                                        
                                                                        <div className='legands'>
                                                                            <div className='legand-1'>
                                                                                <div></div>&nbsp; <p>Total Completion %</p>
                                                                            </div>
                                                                            <div className='legand-1 mt-2'>
                                                                                <div style={{ background: '#ECC30B' }}></div>&nbsp; <p>Student Count</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <HighchartsReact
                                                                        highcharts={Highcharts}
                                                                        options={subjectGraph}
                                                                    />
                                                                </div> */}
                                                            <div className='reports-header  '>
                                                                <div className='d-flex justify-content-between align-items-center header-width'>
                                                                    <h3>Sectionwise Completion Details </h3>
                                                                </div>
                                                            </div>
                                                            <div className='center-table '>
                                                                <TableComponent
                                                                    header={chapterSectionHead}
                                                                    centerList={sectionChapterData}
                                                                    type='subject1'
                                                                />
                                                            </div>
                                                        </Fragment>
                                                }
                                            </div>
                                            : activeTab === 'student' ?
                                                (
                                                    <div className='center-reports mt-2'>
                                                        {
                                                            dataLoading ?
                                                                <div>
                                                                    <DashboardLoader />
                                                                </div>
                                                                :
                                                                <div className='student-graph '>
                                                                    <div className='row'>
                                                                        <div className='col-md-6'>
                                                                            <h1>Engagement Ranking
                                                                                <small onClick={() => getScoreHistory()}><img src='/images/history1.svg' alt='' /> &nbsp;Engagement history </small>
                                                                            </h1>
                                                                            {
                                                                                scoreDetails !== undefined && <div className='mt-3 d-flex align-items-center'>
                                                                                    <div className='section-rank'>
                                                                                        <h2>{scoreDetails?.section_rank === null ? 0 : scoreDetails?.section_rank}</h2>
                                                                                        <p><img src='/images/section-rank.svg' style={{ verticalAlign: 'middle' }} alt='' /> &nbsp;Section Rank</p>
                                                                                    </div>
                                                                                    <div className="program-rank">
                                                                                        <div className="chart-cards">
                                                                                            <h4>{scoreDetails.class_rank === null ? 0 : scoreDetails?.class_rank}</h4>
                                                                                            <div className='current-subjects' >
                                                                                                <img src='/images/program-rank.svg' alt='' />
                                                                                                <div >
                                                                                                    <p>Class Rank</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="chart-cards">
                                                                                            <h4>{scoreDetails?.center_rank || 0}</h4>
                                                                                            <div className='current-subjects' style={{ padding: window.innerWidth > 767 ? 20 : 25 }} >
                                                                                                <img src='/images/section-rank1.svg' alt='' />
                                                                                                <div >
                                                                                                    <p>Center Rank</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className='col-md-6'>
                                                                            <h1>Subject wise content consumption
                                                                                <p>Count of items consumed</p>
                                                                            </h1>
                                                                            <div className='legend-box'>
                                                                                <div className='legend'>
                                                                                    <div className='legend-circle' style={{ background: '#5EB5E7' }}></div>&nbsp; Article
                                                                                </div>
                                                                                <div className='legend'>
                                                                                    <div className='legend-circle' style={{ background: '#00B24B' }}></div>&nbsp; Video
                                                                                </div>
                                                                                <div className='legend'>
                                                                                    <div className='legend-circle' style={{ background: '#ECC30B' }}></div>&nbsp; Quiz
                                                                                </div>
                                                                                <div className='legend'>
                                                                                    <div className='legend-circle' style={{ background: '#FF5A5F' }}></div>&nbsp; Document
                                                                                </div>
                                                                            </div>
                                                                            <div className='subject-chart mt-4'>
                                                                                <HighchartsReact
                                                                                    highcharts={Highcharts}
                                                                                    options={subjectWiseGraph}
                                                                                    oneToone={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                        }
                                                        {
                                                            dataLoading1 ?
                                                                <div>
                                                                    <SmallBoxLoader />
                                                                </div>
                                                                :
                                                                <div className='content-consume mt-2'>
                                                                    <div className="row w-100">
                                                                        <div className="col-lg-6 col-sm-6" style={{ paddingRight: window.innerWidth > 767 ? 10 : 0 }}>
                                                                            <div className='subject-consume'>
                                                                                <div className='legand-box'>
                                                                                    <h3>Last 10 days login trend
                                                                                    </h3>
                                                                                    <div className={window.innerWidth > 767 ? 'legend-box d-flex' : 'legend-box d-flex w-100'}>
                                                                                        <div className='legend px-3 d-flex align-items-center'>
                                                                                            <div className='legend-circle' style={{ background: '#00B24B' }}></div>&nbsp; LoggedIn
                                                                                        </div>
                                                                                        <div className='legend px-3 d-flex align-items-center'>
                                                                                            <div className='legend-circle' style={{ background: '#FF5A5F' }}></div>&nbsp; Absent
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='student-trend row justify-content-center'>
                                                                                    {/* <p className='mt-5' >
                                                                                        Data will be available after 15 days

                                                                                    </p> */}
                                                                                    <div className='col-md-3 mt-4 login-percent'>
                                                                                        <h4>{percentage + '%'}</h4>
                                                                                        <span>{percent && percent.length}/10 Days</span>
                                                                                    </div>
                                                                                    <div className='col-md-9 d-flex flex-wrap justify-content-center'>
                                                                                        {
                                                                                            studentTren !== undefined && studentTren.map(el => {
                                                                                                return (
                                                                                                    <div className='circle mt-3' style={{ background: el.login === 0 ? '#FF5A5F' : '#00B24B' }}>
                                                                                                        {el.date}
                                                                                                    </div>
                                                                                                )
                                                                                            })

                                                                                        }
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-sm-6 pl-md-0 pl1 pr-md-2 " style={{ marginTop: window.innerWidth > 767 ? 0 : 8 }}>
                                                                            <div className='month-consume'>
                                                                                <div className='legand-box'>
                                                                                    <h3>Monthly content consumption
                                                                                        <p>Percentage distribution of items consumed</p>
                                                                                    </h3>
                                                                                    <ul className='legands float-right'>
                                                                                        <li><div className='div-width' style={{ background: '#5EB5E7' }}></div>&nbsp; Article</li>
                                                                                        <li><div className='div-width' style={{ background: '#00B24B' }}></div>&nbsp; Video</li>
                                                                                        <li><div className='div-width' style={{ background: '#ECC30B' }}></div>&nbsp; Quiz</li>
                                                                                        <li><div className='div-width' style={{ background: '#FF5A5F' }}></div>&nbsp; Document</li>
                                                                                    </ul>
                                                                                </div>
                                                                                <div className='subject-chart'>
                                                                                    <HighchartsReact
                                                                                        highcharts={Highcharts}
                                                                                        options={monthlyWise}
                                                                                        oneToone={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        }
                                                        {
                                                            dataLoading2 ?
                                                                <div>
                                                                    <SmallBoxLoader />
                                                                </div>
                                                                :
                                                                <div className='content-consume mt-2'>
                                                                    <div className="row w-100">
                                                                        <div className='col-12' style={{ paddingRight: window.innerWidth > 767 ? 8 : 0 }}>
                                                                            <div className='subject-consume1'>
                                                                                <div className={window.innerWidth > 767 ? 'legand-box w-100 ' : 'legand-box w-100 flex-wrap'} style={{ padding: 20, paddingBottom: 0, borderRadius: 4 }}>
                                                                                    <h3 className='w-100'>Subject wise completion & practice test status
                                                                                        <p>{scoreDetails !== undefined && scoreDetails?.program}</p>
                                                                                    </h3>
                                                                                </div>
                                                                                <div className='legend-status'>
                                                                                    <div className='legend'>
                                                                                        <div className='legend-circle' style={{ background: '#ECC30B' }}></div>&nbsp; Subject completion status
                                                                                    </div>
                                                                                    <div className='legend'>
                                                                                        <div className='legend-circle' style={{ background: '#00B24B' }}></div>&nbsp; Practice  test status
                                                                                    </div>

                                                                                </div>
                                                                                <div className='progres-test'>
                                                                                    {props.testLoading ? (
                                                                                        <BubbleLoader />
                                                                                    ) :
                                                                                        subject !== undefined && !subject.length ?
                                                                                            <div className="empty-list" style={{ height: '188px' }}>
                                                                                                {/* <img src="/images/empty.png" alt="" width='100px' height='100px' /> */}
                                                                                                <p>Subject  is not available for this semester</p>
                                                                                            </div>
                                                                                            :
                                                                                            (window.innerWidth > 767 ?
                                                                                                <div className='w-100'>
                                                                                                    <Slider {...settings} ref={(c) => (sliderRef)}>
                                                                                                        {
                                                                                                            subject !== undefined &&
                                                                                                            subject.map((el, index) => {
                                                                                                                return (
                                                                                                                    <div
                                                                                                                        className={
                                                                                                                            activeSub === el.name
                                                                                                                                ? "card active-subject"
                                                                                                                                : "card"
                                                                                                                        }

                                                                                                                    >
                                                                                                                        <div class="card-body text-center p-0">
                                                                                                                            <div className='card-overlay' onClick={() => onSubjectChange(el.name, el._id)}>

                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                className="d-flex flex-column align-items-center"
                                                                                                                                style={{ padding: "15px 12px" }}
                                                                                                                            >
                                                                                                                                <h1>{el.name}</h1>
                                                                                                                                <div className="mt-2">
                                                                                                                                    <CircularProgressBar chapterComplete={subjectComplete[index]} testComplete={testComplete[index]} />
                                                                                                                                </div>
                                                                                                                                <div className='status'>
                                                                                                                                    <div className='d-flex flex-column align-items-center'>
                                                                                                                                        <h2>{subjectComplete[index]}%</h2>
                                                                                                                                        <h3>{subjectCount[index]}</h3>
                                                                                                                                    </div>&nbsp;
                                                                                                                                    <div className='d-flex flex-column align-items-center'>
                                                                                                                                        <h2 style={{ color: '#00B24B' }}>{testComplete[index]}%</h2>
                                                                                                                                        <h3>{testCount[index]}</h3>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div onClick={() => onSubjectChange(el.name, el._id)} className='d-flex justify-content-center align-items-center details' style={{ color: activeSub === el.title ? '#f5821f' : '#9B9AA3', borderBottom: activeSub === el.title ? '6px solid #f5821f' : 'none', marginTop: activeSub === el.title ? 18 : 22 }}>
                                                                                                                                Details &nbsp;{activeSub === el.title ? <img src='/images/arrow-down-active.svg' style={{ marginTop: 2 }} alt='' /> : <img src='/images/arrow-down.svg' style={{ marginTop: 2 }} alt='' />}

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                );
                                                                                                            })

                                                                                                        }
                                                                                                    </Slider>
                                                                                                    <hr style={{ marginTop: -20, marginRight: 0 }} />
                                                                                                    <div className="content-tabs">
                                                                                                        <button
                                                                                                            className={
                                                                                                                activeStatus === "chapters" ? "active-tab" : null
                                                                                                            }
                                                                                                            onClick={() => onChangeStatus("chapters")}
                                                                                                        >
                                                                                                            Chapters
                                                                                                        </button>
                                                                                                        &nbsp;
                                                                                                        <button
                                                                                                            className={
                                                                                                                activeStatus === "test" ? "active-tab" : null
                                                                                                            }
                                                                                                            onClick={() => onChangeStatus("test")}
                                                                                                        >
                                                                                                            Practise tests
                                                                                                        </button>
                                                                                                        &nbsp;
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                <Fragment>
                                                                                                    <div className='mobile-progress row flex-nowrap' style={{ overflowY: 'hidden' }}>
                                                                                                        {subject !== undefined &&
                                                                                                            subject.map((el, index) => {
                                                                                                                return (
                                                                                                                    <div
                                                                                                                        className={
                                                                                                                            activeSub === el.name
                                                                                                                                ? "card active-subject"
                                                                                                                                : "card"
                                                                                                                        }
                                                                                                                        onClick={() => onSubjectChange(el.name, el._id)}
                                                                                                                    >
                                                                                                                        <div class="card-body text-center p-0">
                                                                                                                            <div
                                                                                                                                className="d-flex flex-column align-items-center"
                                                                                                                                style={{ padding: "15px 12px" }}
                                                                                                                            >
                                                                                                                                <h1>{el.name}</h1>
                                                                                                                                <div className="mt-2">
                                                                                                                                    <CircularProgressBar chapterComplete={subjectComplete[index]} testComplete={testComplete[index]} />
                                                                                                                                </div>
                                                                                                                                <div className='status'>
                                                                                                                                    <div className='d-flex flex-column align-items-center'>
                                                                                                                                        <h2>{subjectComplete[index]}%</h2>
                                                                                                                                        <h3>{subjectCount[index]}</h3>
                                                                                                                                    </div>&nbsp;
                                                                                                                                    <div className='d-flex flex-column align-items-center'>
                                                                                                                                        <h2 style={{ color: '#00B24B' }}>{testComplete[index]}%</h2>
                                                                                                                                        <h3>{testCount[index]}</h3>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className='d-flex justify-content-center align-items-center mt-3 details' style={{ color: activeSub === el.title ? '#f5821f' : '#9B9AA3', borderBottom: activeSub === el.title ? '6px solid #f5821f' : 'none', marginTop: activeSub === el.title ? 18 : 22 }}>
                                                                                                                                Details &nbsp;{activeSub === el.title ? <img src='/images/arrow-down-active.svg' style={{ marginTop: 2 }} alt='' /> : <img src='/images/arrow-down.svg' style={{ marginTop: 2 }} alt='' />}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                );
                                                                                                            })}

                                                                                                    </div>
                                                                                                    <hr style={{ marginTop: 10, marginLeft: -10, marginRight: 0 }} />
                                                                                                    <div className="content-tabs">
                                                                                                        <button
                                                                                                            className={
                                                                                                                activeStatus === "chapters" ? "active-tab" : null
                                                                                                            }
                                                                                                            onClick={() => onChangeStatus("chapters")}
                                                                                                        >
                                                                                                            Chapters
                                                                                                        </button>
                                                                                                        &nbsp;
                                                                                                        <button
                                                                                                            className={
                                                                                                                activeStatus === "test" ? "active-tab" : null
                                                                                                            }
                                                                                                            onClick={() => onChangeStatus("test")}
                                                                                                        >
                                                                                                            Practise tests
                                                                                                        </button>
                                                                                                        &nbsp;
                                                                                                    </div>
                                                                                                </Fragment>)
                                                                                    }

                                                                                </div>
                                                                                <div className='completion-details'>
                                                                                    {
                                                                                        activeStatus === 'test' ?
                                                                                            <div className="test-wise-details">
                                                                                                <ul>
                                                                                                    {chapterLoading ?
                                                                                                        <TopicLoader />
                                                                                                        :
                                                                                                        testList !== undefined && !testList?.length ?
                                                                                                            <div className="empty-test">
                                                                                                                <div className="container">
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-md-5 d-flex flex-column justify-content-center align-items-center no-test">
                                                                                                                            <h4>Oops! No tests available</h4>
                                                                                                                            <p>
                                                                                                                                There are no tests available relevant to this Subject.
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        {window.innerWidth > 767 ? (
                                                                                                                            <div className="col-md-7 d-flex justify-content-center align-items-center">
                                                                                                                                <img
                                                                                                                                    src="/images/empty.png"
                                                                                                                                    width="740px"
                                                                                                                                    height="350px"
                                                                                                                                    alt=""
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    backgroundImage: "url(/images/empty.png)",
                                                                                                                                    backgroundPosition: "center",
                                                                                                                                    backgroundRepeat: "no-repeat",
                                                                                                                                    padding: 100,
                                                                                                                                    backgroundSize: "contain",
                                                                                                                                    width: 400,
                                                                                                                                }}
                                                                                                                            ></div>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> :
                                                                                                            testList !== undefined &&
                                                                                                            testList.map((el, index) => {
                                                                                                                return window.innerWidth > 767 ? (
                                                                                                                    <Fragment>
                                                                                                                        <li key={index}>
                                                                                                                            <div className="topic-name">
                                                                                                                                {index + 1}. {el.title}
                                                                                                                            </div>
                                                                                                                            <div className="topic-time">
                                                                                                                                <p>
                                                                                                                                    {el.questions} <span>Questions</span>
                                                                                                                                </p>
                                                                                                                                <p>
                                                                                                                                    {el.time}
                                                                                                                                    <span>Minutes</span>
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div className="last-taken">
                                                                                                                                {!el.attempts ? (
                                                                                                                                    <img
                                                                                                                                        src="/images/check_grey.svg"
                                                                                                                                        width="20px"
                                                                                                                                        alt=""
                                                                                                                                    />
                                                                                                                                ) : (
                                                                                                                                    <img src="/images/check_green1.svg" alt="" />
                                                                                                                                )}
                                                                                                                                &nbsp; &nbsp;
                                                                                                                                {el.taken !== null ? (
                                                                                                                                    <p>
                                                                                                                                        Last taken <br /> {convertDate(el.taken)}
                                                                                                                                    </p>
                                                                                                                                ) : (
                                                                                                                                    " "
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                            <div className="last-score">
                                                                                                                                {el.taken !== null ? (
                                                                                                                                    <p>
                                                                                                                                        {el.score !== undefined && el.score.toFixed(2)}% <span>Last Score</span>
                                                                                                                                    </p>
                                                                                                                                ) : (
                                                                                                                                    " "
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                            <div className="last-history">
                                                                                                                                {!el.history.length ? (
                                                                                                                                    ""
                                                                                                                                ) : (
                                                                                                                                    <OverlayTrigger
                                                                                                                                        key="bottom-start"
                                                                                                                                        trigger="click"
                                                                                                                                        placement="bottom-start"
                                                                                                                                        rootClose={true}
                                                                                                                                        overlay={
                                                                                                                                            <Popover
                                                                                                                                                id={"popover-contained"}
                                                                                                                                                style={{
                                                                                                                                                    width: "246px",
                                                                                                                                                    borderRadius: 2,
                                                                                                                                                    zIndex: 3,
                                                                                                                                                    border: "none",
                                                                                                                                                    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)",
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <PopoverHistory
                                                                                                                                                    history={el.history}
                                                                                                                                                    subject={el.title}
                                                                                                                                                    type='Test'
                                                                                                                                                    onExitPopover={onExitPopover}
                                                                                                                                                />
                                                                                                                                            </Popover>
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        <div className="d-flex">
                                                                                                                                            <img src="/images/history.svg" alt="" />
                                                                                                                                            &nbsp;&nbsp;
                                                                                                                                            {el.attempts}
                                                                                                                                        </div>
                                                                                                                                    </OverlayTrigger>
                                                                                                                                )}
                                                                                                                            </div>

                                                                                                                        </li>
                                                                                                                    </Fragment>
                                                                                                                ) : (
                                                                                                                    <Fragment>
                                                                                                                        <li>
                                                                                                                            <div className='ml-2'>
                                                                                                                                {el.attempts === 0 ? (
                                                                                                                                    <img
                                                                                                                                        src="/images/check_grey.svg"
                                                                                                                                        width="20px"
                                                                                                                                        alt=""
                                                                                                                                    />
                                                                                                                                ) : (
                                                                                                                                    <img src="/images/check_green1.svg" alt="" />
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                            <div className="last-taken1 ml-4" >
                                                                                                                                <h3>
                                                                                                                                    {index + 1}. {el.title}
                                                                                                                                </h3>
                                                                                                                                {el.taken !== null ? (
                                                                                                                                    <p>
                                                                                                                                        Last taken : {el.taken.split("T")[0]} &nbsp;
                                                                                                                                        <img src="/images/info1.svg" onClick={() => openDetails(el)} style={{ verticalAlign: "top" }} alt="" />
                                                                                                                                    </p>)
                                                                                                                                    :
                                                                                                                                    <p className='d-flex flex-row'>
                                                                                                                                        {el.questions} Questions &nbsp;&nbsp;
                                                                                                                                        {el.time} Minutes
                                                                                                                                    </p>
                                                                                                                                }
                                                                                                                            </div>

                                                                                                                        </li>
                                                                                                                    </Fragment>
                                                                                                                );
                                                                                                            })}
                                                                                                </ul>
                                                                                            </div> :
                                                                                            <div id="accordion" className="accordion">
                                                                                                {chapterLoading ?
                                                                                                    <TopicLoader />
                                                                                                    :
                                                                                                    subjectTopics !== undefined && subjectTopics?.length > 0 ?
                                                                                                        subjectTopics?.map((items, index) => {
                                                                                                            return (
                                                                                                                <div className="card mb-0" >
                                                                                                                    <div className={"card-header collapsed"} data-toggle="collapse" href={`#${index}`} onClick={() => items?.data?.length === 0 ? '' : getSubtopics(index, items._id, props.centerObj?._id, items)}>
                                                                                                                        <a className="card-title" >
                                                                                                                            <div style={{ marginLeft: window.innerWidth > 767 ? -7 : 8, width: '80%' }} className='d-flex justify-content-between align-items-center cursor-pointer'>
                                                                                                                                <p> {items?.name !== null && <EllipsisText text={items?.name} length={window.innerWidth > 767 ? 30 : 20} />}</p>
                                                                                                                                <div className='d-flex align-items-center' style={{ width: window.innerWidth > 767 ? '35%' : '50%' }}>
                                                                                                                                    <h6>Content</h6>&nbsp;&nbsp;
                                                                                                                                    <div className='' style={{ height: 16, marginTop: 11 }}>
                                                                                                                                        <ProgressBar completed={items.percentage} bgcolor='#00b24b' view={items.view} fill='#EDF3F3' labelColor='#00B24B' mobileWidth={65} margin={75} />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </a>
                                                                                                                    </div>
                                                                                                                    <div id={index} className={items.name === chapter ? 'card-body collapse show' : 'card-body collapse'} data-parent={`#accordion`} >
                                                                                                                        <ul className='content-holder'>
                                                                                                                            {
                                                                                                                                isSubtopicLoading ?
                                                                                                                                    <div className='px-3'>
                                                                                                                                        <TopicLoader />
                                                                                                                                    </div>
                                                                                                                                    :
                                                                                                                                    items?.data?.map((el, i) => {
                                                                                                                                        return (
                                                                                                                                            <li key={i} className="resource border-bottom"
                                                                                                                                            >
                                                                                                                                                <div className="col-md-12 col-12 recent-title" >
                                                                                                                                                    <div className={window.innerWidth > 767 ? 'd-flex align-items-center' : 'd-flex align-items-center flex-wrap'}>
                                                                                                                                                        <h1>  <EllipsisText text={el.topic} length={30} />
                                                                                                                                                        </h1>
                                                                                                                                                        <div className='d-flex justify-content-between align-items-center w-100 overflow-auto'>
                                                                                                                                                            <h2>
                                                                                                                                                                <p>{el.total_percentage}</p>
                                                                                                                                                                <span style={{ fontWeight: '400' }}>{el.total_count}<br />Total</span>
                                                                                                                                                            </h2>
                                                                                                                                                            <h2>
                                                                                                                                                                <p>{el.articles_percentage}</p>
                                                                                                                                                                <span style={{ fontWeight: '400' }}>{el.articles_count}<br />Articles</span>
                                                                                                                                                            </h2>
                                                                                                                                                            <h2>
                                                                                                                                                                <p>{el.videos_percentage}</p>
                                                                                                                                                                <span style={{ fontWeight: '400' }}>{el.videos_count} <br />Videos</span>
                                                                                                                                                            </h2>
                                                                                                                                                            <h2>
                                                                                                                                                                <p>{el.documents_percentage}</p>
                                                                                                                                                                <span style={{ fontWeight: '400' }}>{el.documents_count} <br />Documents</span>
                                                                                                                                                            </h2>
                                                                                                                                                            <h2 className='quiz-count'>
                                                                                                                                                                <p>{el.quizzes_percentage}</p>
                                                                                                                                                                <span style={{ fontWeight: '400' }}>{el.quizzes_count} <br /> <small style={{ fontWeight: 'bold', color: '#f5821f' }}>Quizzes&nbsp;

                                                                                                                                                                    {

                                                                                                                                                                        (
                                                                                                                                                                            <OverlayTrigger
                                                                                                                                                                                key="bottom-start"
                                                                                                                                                                                trigger="click"
                                                                                                                                                                                placement="bottom-start"
                                                                                                                                                                                rootClose={true}
                                                                                                                                                                                overlay={
                                                                                                                                                                                    <Popover
                                                                                                                                                                                        id={"popover-contained"}
                                                                                                                                                                                        style={{
                                                                                                                                                                                            width: "246px",
                                                                                                                                                                                            borderRadius: 2,
                                                                                                                                                                                            zIndex: 3,
                                                                                                                                                                                            border: "none",
                                                                                                                                                                                            boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)",
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <PopoverHistory
                                                                                                                                                                                            history={quizHistory}
                                                                                                                                                                                            type='Quiz'
                                                                                                                                                                                            onExitPopover={onExitPopover}
                                                                                                                                                                                        />
                                                                                                                                                                                    </Popover>
                                                                                                                                                                                }
                                                                                                                                                                            >
                                                                                                                                                                                <img src='/images/info-icon.svg' alt='' onClick={async () => {
                                                                                                                                                                                    await props.clearQuizHistory();
                                                                                                                                                                                    await props.getQuizHistory(items._id, el._id, props.centerObj?._id)
                                                                                                                                                                                }
                                                                                                                                                                                } />
                                                                                                                                                                            </OverlayTrigger>
                                                                                                                                                                        )}

                                                                                                                                                                </small>
                                                                                                                                                                </span>
                                                                                                                                                            </h2>
                                                                                                                                                        </div>

                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </li>
                                                                                                                                        )
                                                                                                                                    })
                                                                                                                            }
                                                                                                                        </ul>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            )
                                                                                                        }) :
                                                                                                        <div className="empty-test">
                                                                                                            <div className="container">
                                                                                                                <div className="row">
                                                                                                                    <div className="col-md-5 d-flex flex-column justify-content-center align-items-center no-test">
                                                                                                                        <h4>Oops! No chapters available</h4>
                                                                                                                        <p>
                                                                                                                            There are no chapters available relevant to this Subject.
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    {window.innerWidth > 767 ? (
                                                                                                                        <div className="col-md-7 d-flex justify-content-center align-items-center">
                                                                                                                            <img
                                                                                                                                src="/images/empty.png"
                                                                                                                                width="740px"
                                                                                                                                height="350px"
                                                                                                                                alt=""
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    ) : (
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                backgroundImage: "url(/images/empty.png)",
                                                                                                                                backgroundPosition: "center",
                                                                                                                                backgroundRepeat: "no-repeat",
                                                                                                                                padding: 100,
                                                                                                                                backgroundSize: "contain",
                                                                                                                                width: 400,
                                                                                                                            }}
                                                                                                                        ></div>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                }
                                                                                            </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                ) :
                                                activeTab === 'assessment' ?
                                                    < Fragment >
                                                        {!isReport ?
                                                            <Fragment>
                                                                {/* <div className='center-reports mt-2'>
                                                                    <div className='center-btns'>
                                                                        <button className={activeAssessment === 'dbda' ? "active-btn" : null} onClick={() => onAssChange('dbda')}>DBDA REPORT</button>&nbsp; &nbsp;
                                                                        <button className={activeAssessment === 'riasec' ? "active-btn" : null} onClick={() => onAssChange('riasec')}>RIASEC REPORT</button>
                                                                    </div>
                                                                </div> */}
                                                                {activeType?.name === 'dbda' ?
                                                                    <div className='ability-holder ' style={{ width: '99%', background: 'transparent' }}>
                                                                        <div className="table-admin" >
                                                                            <Table responsive >
                                                                                <thead>
                                                                                    <tr className="header-table">
                                                                                        <th>Ability </th>
                                                                                        <th className='text-center'>Questions</th>
                                                                                        <th className='text-center'>Duration</th>
                                                                                        <th className='text-center'>Raw score</th>
                                                                                        <th className='text-center'>Sten score</th>
                                                                                        <th className='text-center'>Performance</th>
                                                                                        <th className='text-center'>Test Taken</th>
                                                                                        <th className='text-center'>Action</th>
                                                                                    </tr>
                                                                                    <hr />
                                                                                </thead>
                                                                                <tbody className="table-body" >
                                                                                    {dataLoading1 ?
                                                                                        <TopicLoader /> :
                                                                                        (abilities !== undefined && abilities?.length > 0 ?
                                                                                            abilities?.map((el, i) => {
                                                                                                return (
                                                                                                    <tr key={i} className='cursor-pointer'
                                                                                                    >

                                                                                                        <td style={{ width: '30%' }}  >
                                                                                                            {el.title}
                                                                                                        </td>
                                                                                                        <td className='text-center'>{el.question_count}</td>
                                                                                                        <td className='text-center'>{convertMillisecondsToTime(el.duration)}</td>
                                                                                                        <td className='text-center'>{el.title === 'Psychomotor ability (PM)' ?
                                                                                                            <select className='form-control' disabled={ability?.length} value={el.score} onChange={(e) => dispatch(changeScore(studentId, el?._id, e.target.value))}>
                                                                                                                {scoreArr?.map(el => {
                                                                                                                    <option hidden>Score</option>
                                                                                                                    return (
                                                                                                                        <option value={el}>{el}</option>
                                                                                                                    )
                                                                                                                })}
                                                                                                            </select>
                                                                                                            : el.completed ? el.score : '-'}</td>
                                                                                                        <td className='text-center'>{el.completed ? el.sten_score : '-'}</td>
                                                                                                        <td className='text-center'>{el.sten_score <= 3 ? 'Low' : (el.sten_score > 3 && el.sten_score <= 7) ? 'Medium' : "High"}</td>
                                                                                                        <td className='text-center'>{el.completed ? convertToDate(el.test_taken) : 'Not Taken'}</td>
                                                                                                        <td className='text-center cursor-pointer' style={{ color: (el.title === 'Psychomotor ability (PM)' || !el.completed) ? '' : '#f5821f' }} onClick={() => (el.title === 'Psychomotor ability (PM)' || !el.completed) ? null : getQuestions(el)}>{(el.title === 'Psychomotor ability (PM)' || !el.completed) ? 'NA' : <img src='/images/view_grey.svg' width={20} alt='' />}</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })

                                                                                            :
                                                                                            <tr>
                                                                                                <div className='empty-list'>
                                                                                                    <img src='/images/empty.png' alt='' />
                                                                                                </div>
                                                                                            </tr>
                                                                                        )
                                                                                    }
                                                                                </tbody>
                                                                            </Table>


                                                                        </div>
                                                                        {abilities?.some(el => (!el.sten_score || el.sten_score === 0)) ? null :
                                                                            (
                                                                                dataLoading1 ? '' :
                                                                                    <Fragment>
                                                                                        {ability?.length ? null : <button className='report-btn' onClick={() => { dispatch(getAssessmentGraph(studentId, activeType?._id)) }}>Generate Report</button>}

                                                                                        {
                                                                                            ability?.length ?
                                                                                                <div className='row me-0 ms-0 mt-5'>
                                                                                                    <div className='col-md-10'>
                                                                                                        <HighchartsReact
                                                                                                            highcharts={Highcharts}
                                                                                                            options={abilityTrend}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="html2pdf__page-break"></div>
                                                                                                    <div className='d-flex align-items-center flex-wrap mt-5'>
                                                                                                        {
                                                                                                            abilities && abilities?.map((el, i) => {
                                                                                                                return (
                                                                                                                    <div className='career-interest-progress mt-2'>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                backgroundColor: el.title === 'Verbal Ability (VA)' ? "#F1FEEB" : el.title === 'Numerical ability (NA)' ? '#EBFAFE' : el.title === 'Spatial ability (SA)' ? "#EBF4FE" : el.title === 'Closure ability (CA)' ? "#FFF3F3" : el.title === 'Clerical ability (CL)' ? '#FFEBE1' : el.title === 'Reasoning ability (RA)' ? "#FDF8DF" : el.title === 'Mechanical ability (MA)' ? '#FFEBE1' : '#FFF3F3',
                                                                                                                                width: '88%', padding: 15
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <h6>
                                                                                                                                {el.title}
                                                                                                                            </h6>
                                                                                                                            <p>{getDescByTitle(el.title)}</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                </div> :
                                                                                                null
                                                                                        }
                                                                                    </Fragment>)

                                                                        }

                                                                    </div>
                                                                    : activeType?.name === 'tamanna' ?
                                                                        <div className='ability-holder ' style={{ width: '99%', background: 'transparent' }}>
                                                                            <div className="table-admin" >
                                                                                <Table responsive >
                                                                                    <thead>
                                                                                        <tr className="header-table">
                                                                                            <th>Ability </th>
                                                                                            <th className='text-center'>Questions</th>
                                                                                            <th className='text-center'>Duration</th>
                                                                                            <th className='text-center'>Raw score</th>
                                                                                            <th className='text-center'>Sten score</th>
                                                                                            <th className='text-center'>Test Taken</th>
                                                                                            <th className='text-center'>Action</th>
                                                                                        </tr>
                                                                                        <hr />
                                                                                    </thead>
                                                                                    <tbody className="table-body" >
                                                                                        {dataLoading1 ?
                                                                                            <TopicLoader /> :
                                                                                            (abilities !== undefined && abilities?.length > 0 ?
                                                                                                abilities?.map((el, i) => {
                                                                                                    return (
                                                                                                        <tr key={i} className='cursor-pointer'
                                                                                                        >

                                                                                                            <td style={{ width: '30%' }}  >
                                                                                                                {el.title}
                                                                                                            </td>
                                                                                                            <td className='text-center'>{el.question_count}</td>
                                                                                                            <td className='text-center'>{convertMillisecondsToTime(el.duration)}</td>
                                                                                                            <td className='text-center'>{el.title === 'Psychomotor ability (PM)' ?
                                                                                                                <select className='form-control' disabled={ability?.length} value={el.score} onChange={(e) => dispatch(changeScore(studentId, el?._id, e.target.value))}>
                                                                                                                    {scoreArr?.map(el => {
                                                                                                                        <option hidden>Score</option>
                                                                                                                        return (
                                                                                                                            <option value={el}>{el}</option>
                                                                                                                        )
                                                                                                                    })}
                                                                                                                </select>
                                                                                                                : el.completed ? el.score : '-'}</td>
                                                                                                            <td className='text-center'>{el.completed ? el.sten_score : '-'}</td>
                                                                                                            <td className='text-center'>{el.completed ? convertToDate(el.test_taken) : 'Not Taken'}</td>
                                                                                                            <td className='text-center cursor-pointer' style={{ color: (el.title === 'Psychomotor ability (PM)' || !el.completed) ? '' : '#f5821f' }} onClick={() => (el.title === 'Psychomotor ability (PM)' || !el.completed) ? null : getQuestions(el)}>{(el.title === 'Psychomotor ability (PM)' || !el.completed) ? 'NA' : <img src='/images/view_grey.svg' width={20} alt='' />}</td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                })

                                                                                                :
                                                                                                <tr>
                                                                                                    <div className='empty-list'>
                                                                                                        <img src='/images/empty.png' alt='' />
                                                                                                    </div>
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                    </tbody>
                                                                                </Table>


                                                                            </div>
                                                                            {
                                                                                abilities?.some(el => (!el.sten_score || el.sten_score === 0)) ? null :
                                                                                    (
                                                                                        dataLoading1 ? '' :
                                                                                            <Fragment>
                                                                                                {ability?.length ? null : <button className='report-btn' onClick={() => { dispatch(getAssessmentGraph(studentId, activeType?._id)) }}>Generate Report</button>}

                                                                                                {
                                                                                                    ability?.length ?
                                                                                                        <div className='row me-0 ms-0 mt-5'>
                                                                                                            <div className='col-md-10'>
                                                                                                                <HighchartsReact
                                                                                                                    highcharts={Highcharts}
                                                                                                                    options={abilityTrend}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className="html2pdf__page-break"></div>
                                                                                                            <div className='d-flex align-items-center flex-wrap mt-5'>
                                                                                                                {
                                                                                                                    abilities && abilities?.map((el, i) => {
                                                                                                                        return (
                                                                                                                            <div className='career-interest-progress mt-2'>
                                                                                                                                <div
                                                                                                                                    style={{
                                                                                                                                        backgroundColor: el.title === 'Language Aptitude (LA)' ? "#F1FEEB" : el.title === 'Abstract Reasoning (AR)' ? '#EBFAFE' : el.title === 'Verbal Reasoning (VR)' ? "#EBF4FE" : el.title === 'Mechanical Reasoning (MR)' ? "#FFF3F3" : el.title === 'Numerical Aptitude (NA)' ? '#FFEBE1' : el.title === 'Reasoning ability (RA)' ? "#FDF8DF" : el.title === 'Mechanical ability (MA)' ? '#FFEBE1' : '#FFF3F3',
                                                                                                                                        width: '88%', padding: 15
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <h6>
                                                                                                                                        {el.title}
                                                                                                                                    </h6>
                                                                                                                                    <p>{getDescByTitle(el.title)}
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div> :
                                                                                                        null
                                                                                                }
                                                                                                <div className="html2pdf__page-break"></div>
                                                                                                {ability?.length ?
                                                                                                    <div className='t-ability row me-0 ms-0 mt-5'>
                                                                                                        {TamannaAbility && TamannaAbility?.map((el, i) => {
                                                                                                            return (
                                                                                                                <Fragment>

                                                                                                                    <div className='col-md-4 col-6 mt-3'>
                                                                                                                        <h4>{el.occupation}</h4>
                                                                                                                        <ul>
                                                                                                                            {
                                                                                                                                el.ability.split(',')?.map(el1 => {
                                                                                                                                    return (
                                                                                                                                        <li>{el1}</li>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }
                                                                                                                        </ul>
                                                                                                                    </div>
                                                                                                                    {(((i + 1) % 15) === 0) && <div className="html2pdf__page-break"></div>}
                                                                                                                </Fragment>
                                                                                                            )
                                                                                                        })}
                                                                                                    </div> :
                                                                                                    null
                                                                                                }

                                                                                            </Fragment>)

                                                                            }

                                                                        </div> :

                                                                        <div className='ability-holder ' style={{ width: '99%', background: 'transparent' }}>
                                                                            <div className="table-admin" >
                                                                                <Table responsive >
                                                                                    <thead>
                                                                                        <tr className="header-table">
                                                                                            <th>Text taken on</th>
                                                                                            <th>Time</th>
                                                                                            <th>Code</th>
                                                                                            <th></th>
                                                                                        </tr>
                                                                                        <hr />
                                                                                    </thead>
                                                                                    <tbody className="table-body" >
                                                                                        {dataLoading1 ?
                                                                                            <TopicLoader /> :
                                                                                            (assessmentReports !== undefined && assessmentReports?.length > 0 ?
                                                                                                assessmentReports?.map((el, i) => {
                                                                                                    return (
                                                                                                        <tr key={i} className='cursor-pointer'
                                                                                                        >

                                                                                                            <td>{convertDate(el.createdAt)}</td>
                                                                                                            <td>{formatAMPM(el.createdAt)}</td>
                                                                                                            <td style={{ textTransform: 'capitalize', }}>{getCodes(el.riasec)}</td>
                                                                                                            <td >
                                                                                                                <p style={{ cursor: 'pointer', color: '#333269' }}
                                                                                                                    onClick={() => qLoading ? null : openReport(el._id)}
                                                                                                                ><img src='/images/view_grey.svg' width={20} alt='' /></p></td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                })

                                                                                                :
                                                                                                <tr>
                                                                                                    <div className='empty-list'>
                                                                                                        <img src='/images/empty.png' alt='' />
                                                                                                    </div>
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                    </tbody>
                                                                                </Table>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </Fragment> :

                                                            <TestReport
                                                                back={() => {
                                                                    setIsReport(false)
                                                                    activeType('riasec')
                                                                }}
                                                            />
                                                        }
                                                    </Fragment> :
                                                    activeTab === 'test' ?
                                                        <div className='center-reports  mt-2'>
                                                            <div className='reports-header  '>
                                                                <div className='d-flex justify-content-between align-items-center header-width'>
                                                                    <h3>Classwise Subject Test Completion Details </h3>
                                                                </div>
                                                            </div>
                                                            {!isListView ?
                                                                <div className='center-table '>
                                                                    <TableComponent
                                                                        header={classSubjectHead}
                                                                        centerList={classWiseSubjectData}
                                                                        getPracticeTest={(page, subjectId) => getTests(page, subjectId)}
                                                                        type='classsubject'
                                                                    />
                                                                </div> :
                                                                <div className='completion-details '>
                                                                    <div className="test-wise-details mt-3">
                                                                        <ul>
                                                                            <InfiniteScroll
                                                                                dataLength={practiceTestList !== undefined && practiceTestList?.length} //This is important field to render the next data
                                                                                next={fetchTestData}
                                                                                hasMore={true}
                                                                            >
                                                                                {testsLoading ?
                                                                                    <TopicLoader /> :
                                                                                    practiceTestList !== undefined && !practiceTestList.length ?
                                                                                        <div className="empty-test">
                                                                                            <div className="container">
                                                                                                <div className="row">
                                                                                                    <div className="col-md-5 d-flex flex-column justify-content-center align-items-center no-test">
                                                                                                        <h4>Oops! No tests available</h4>
                                                                                                        <p>
                                                                                                            There are no tests available relevant to this Subject.
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    {window.innerWidth > 767 ? (
                                                                                                        <div className="col-md-7 d-flex justify-content-center align-items-center">
                                                                                                            <img
                                                                                                                src="/images/empty.png"
                                                                                                                width="740px"
                                                                                                                height="350px"
                                                                                                                alt=""
                                                                                                            />
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <div
                                                                                                            style={{
                                                                                                                backgroundImage: "url(/images/empty.png)",
                                                                                                                backgroundPosition: "center",
                                                                                                                backgroundRepeat: "no-repeat",
                                                                                                                padding: 100,
                                                                                                                backgroundSize: "contain",
                                                                                                                width: 400,
                                                                                                            }}
                                                                                                        ></div>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> :
                                                                                        practiceTestList !== undefined &&
                                                                                        practiceTestList.map((el, index) => {
                                                                                            return window.innerWidth > 767 ? (
                                                                                                <Fragment>
                                                                                                    <li key={index} style={{ marginTop: 15 }}>
                                                                                                        <div className="topic-name">
                                                                                                            {index + 1}. {el.title}
                                                                                                        </div>
                                                                                                        <div className="topic-time" >
                                                                                                            <p style={{ fontSize: 14, color: '#222222' }}>
                                                                                                                {el.subject_name} <span>Subject</span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="topic-time" style={{ width: '5%' }}>
                                                                                                            <p>
                                                                                                                {el.no_ques} <span>Questions</span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="last-score">
                                                                                                            <p>
                                                                                                                {el.no_students !== undefined && el.no_students} <span>Total taken</span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="last-score">
                                                                                                            <p>
                                                                                                                {el.avg_score !== undefined && ((el.avg_score === 0 || el.avg_score == null) ? el.avg_score : el.avg_score?.toFixed(2))}% <span>Avg Score</span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="last-history" style={{ width: '5%' }}>
                                                                                                            {
                                                                                                                el.no_students >= 1 &&
                                                                                                                <div className="d-flex" onClick={() => getTestsHistory(el._id, el.no_ques)}>
                                                                                                                    <img src="/images/history.svg" alt="" />
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                        {/* <div onClick={() => { openQuesView(el._id) }} style={{ color: '#f5821f', fontWeight: 'bold', cursor: 'pointer' }}>
                                                                                                            View Details
                                                                                                        </div> */}
                                                                                                    </li>
                                                                                                </Fragment>
                                                                                            ) : (
                                                                                                <Fragment>
                                                                                                    <li>

                                                                                                        <div className="last-taken1 ml-4" >
                                                                                                            <h3>
                                                                                                                {index + 1}. {el.title}
                                                                                                            </h3>
                                                                                                            {
                                                                                                                el.no_students >= 1 ? (
                                                                                                                    <p>
                                                                                                                        Total taken : {el.no_students} &nbsp;
                                                                                                                        <img src="/images/info1.svg" onClick={() => getTestsHistory(el._id, el.no_ques)} style={{ verticalAlign: "top" }} alt="" />
                                                                                                                    </p>)
                                                                                                                    :
                                                                                                                    <p className='d-flex flex-row'>
                                                                                                                        {el.no_ques} Questions &nbsp;
                                                                                                                    </p>
                                                                                                            }
                                                                                                        </div>

                                                                                                    </li>
                                                                                                </Fragment>
                                                                                            );
                                                                                        })}
                                                                            </InfiniteScroll>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        : null
                    }

                </Fragment>
            }


            {
                ["bottom"].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Drawer
                            anchor={anchor}
                            open={isOpenDetails}
                            className="mobile-drawer3"
                        >
                            {testDetail !== undefined && (
                                <div className="test-info">
                                    <h3>Test info</h3>
                                    <div className="close-details" onClick={() => setOpenDetails(false)}>
                                        <img src="/images/close1.svg" alt="" />
                                    </div>
                                    <p>{testDetail.title}</p>
                                    <div className="test-ques">
                                        <p>
                                            {testDetail?.questions}
                                            <br /> <span>Question</span>
                                        </p>
                                        &nbsp;
                                        <p>
                                            {testDetail?.time}
                                            <br /> <span>Minutes</span>
                                        </p>
                                        &nbsp;&nbsp;
                                        {testDetail.score !== " " ? (
                                            <p>
                                                {testDetail?.score !== undefined && testDetail?.score.toFixed(2)}%<br /> <span>Last Score</span>
                                            </p>
                                        ) : null}
                                    </div>
                                    <ul className="history">
                                        {testDetail?.history && testDetail?.history.map((el) => {
                                            return (
                                                <li>
                                                    <p>{el.date.split("T")[0]}</p>
                                                    <p style={{ fontWeight: "normal" }}>{el.score}</p>
                                                    <p style={{ fontWeight: "normal" }}>
                                                        {el.percentage}%
                                                    </p>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            )}
                        </Drawer>
                    </React.Fragment>
                ))
            }

            {/* ########## submit answer  modal ############### */}
            <Modal
                show={isOpen}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={'question-modal'}
            >
                <Fragment>

                    <div className='questions-planning' style={{ minHeight: 490 }}>
                        <div className='add-header p-2' style={{ width: '100%' }}>
                            <div className='d-flex align-items-center'>
                                {<img src='/images/back.svg' alt='' width={20} height={20} onClick={() => setIsOpen(false)} />}
                                <div style={{ marginLeft: 20 }}>
                                    <h2>Ability : {details?.title}</h2>
                                </div>
                            </div>

                            <div className='d-flex align-items-center'>

                            </div>
                        </div>
                        <div>
                            {qLoading ?
                                <TopicLoader /> :
                                abilityQuestions !== undefined && abilityQuestions?.length ?
                                    (details?.title === 'Verbal Ability (VA)' ?
                                        <ul className='schedule-list ' >
                                            <Fragment>
                                                <h3 className='my-2 mx-2'>Verbal Ability(VA) Part-1</h3>
                                                <div className='d-flex flex-wrap'>
                                                    {abilityQuestions !== undefined && abilityQuestions.slice(0, 15)?.map((el, index) => {
                                                        return (
                                                            <li key={index} className='d-flex' style={{ pointerEvents: details?.completed ? 'none' : 'auto', width: '32%', marginLeft: 5, marginTop: 5 }} >
                                                                <div className='marks-box d-flex align-items-center w-100' style={{ background: el.is_correct ? '#00cc4b' : '', color: el.is_correct ? '#fff' : "" }}>
                                                                    <p style={{ width: '50%', color: '#fff' }}>{el.question}</p>
                                                                    <div className='d-flex align-items-center justify-content-end' style={{ width: '45%', marginRight: 12 }} >
                                                                        {
                                                                            el.options?.map((el1, i) => {
                                                                                return (
                                                                                    <div class="form-check form-check-inline">
                                                                                        <label class="form-check-label" htmlFor={`inlineRadio${index}-${i}`}>{el1.title}</label>
                                                                                        <input class="form-check-input" type="radio" name={`inlineRadioOptions${index}`} id={`inlineRadio${index}-${i}`} checked={el.user_answer === el1._id ? true : false} value={el.user_answer} />
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                    }
                                                </div>
                                                <br />
                                                <h3 className='my-2 mx-2'>Verbal Ability(VA) Part-2</h3>
                                                <div className='d-flex flex-wrap'>
                                                    {abilityQuestions !== undefined && abilityQuestions.slice(15, 24)?.map((el, index) => {
                                                        return (
                                                            <li key={index} className='d-flex' style={{ pointerEvents: details?.completed ? 'none' : 'auto', width: '32%', marginLeft: 5, marginTop: 5 }} >
                                                                <div className='marks-box d-flex align-items-center w-100' style={{ background: el.is_correct ? '#00cc4b' : '', color: el.is_correct ? '#fff' : "" }} >
                                                                    <p style={{ width: '50%', color: "#FFF" }}>{el.question}</p>
                                                                    <div className='d-flex align-items-center justify-content-end' style={{ width: '45%', marginRight: 12 }} >
                                                                        {
                                                                            el.options?.map((el1, i) => {
                                                                                return (
                                                                                    <div class="form-check form-check-inline">
                                                                                        <label class="form-check-label" htmlFor={`inlineRadio${index + 16}-${i}`}>{el1.title}</label>
                                                                                        <input class="form-check-input" type="radio" name={`inlineRadioOptions${index + 16}`} id={`inlineRadio${index + 16}-${i}`} checked={el.user_answer === el1._id ? true : false} value={el.user_answer} />
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </Fragment>
                                        </ul>
                                        :
                                        <ul className='schedule-list d-flex flex-wrap' >
                                            {abilityQuestions !== undefined && abilityQuestions?.map((el, index) => {
                                                return (
                                                    <li key={index} className={((details?.title === 'Spatial ability (SA)') || (details?.title === 'Clerical ability (CL)')) ? 'extra d-flex' : 'no-extra d-flex'} style={{ pointerEvents: details?.completed ? 'none' : 'auto', width: ((details?.title === 'Spatial ability (SA)') || (details?.title === 'Clerical ability (CL)')) ? '23.8%' : '32%', marginLeft: 5, marginTop: 5 }} >
                                                        <div className='marks-box d-flex align-items-center w-100' style={{ background: el.is_correct ? '#00cc4b' : '', color: el.is_correct ? '#fff' : "" }} >
                                                            <p style={{ width: '50%', color: el.is_correct ? '#fff' : "" }}>{el.question}</p>
                                                            <div className='d-flex align-items-center justify-content-end' style={{ width: '45%', marginRight: 12 }} >
                                                                {
                                                                    el.options?.map((el1, i) => {
                                                                        return (
                                                                            <div class="form-check form-check-inline">
                                                                                <label class="form-check-label" htmlFor={`inlineRadio${index}-${i}`}>{el1.title}</label>
                                                                                <input class="form-check-input" type="radio" name={`inlineRadioOptions${index}`} id={`inlineRadio${index}-${i}`} checked={el.user_answer === el1._id ? true : false} value={el.user_answer} />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                            }

                                        </ul>)
                                    :
                                    <div className='empty-list'>
                                        <img src='/images/empty.png' alt='' />
                                        <p>No Questions found</p>
                                    </div>
                            }

                        </div>
                    </div>
                </Fragment>
            </Modal>




            {/* ################## section wise details modal ############################ */}
            <Modal
                show={isDetails}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"details-modal"}
            >
                <Modal.Body style={{ padding: '22px' }}>
                    {/* <h2>Section Wise Chapter Details</h2> */}

                    <div className="sticky-close" onClick={() => {
                        setIsDetails(false)
                        setOpenMenu(false)
                    }}>
                        <img src="/images/close1.svg" width='15px' alt='' />
                    </div>
                    <div className='float-right'>
                        <button onClick={() => downloadChapterdata(props.chaptersData)} style={{ background: '#f5821f', color: '#fff', padding: '5px 10px', border: 'none', borderRadius: 4 }}>Download</button>
                    </div>
                    <div style={{ maxHeight: 500, marginTop: 40, overflow: 'auto' }}>
                        <Table responsive className='mt-3'>
                            <thead>
                                <th>Chapter Name</th>
                                <th>Students</th>
                                <th>{'<35% Complete'}</th>
                                <th>35-49% Complete</th>
                                <th>50-89% Complete</th>
                                <th>90-100% Complete</th>
                            </thead>
                            <tbody>
                                {props.chaptersData && props.chaptersData?.map((el, i) => {
                                    return (
                                        <tr>
                                            <td className='text-left'>{el.name}</td>
                                            <td>{el.students}</td>
                                            <td>
                                                <Overlay
                                                    show={openMenu}
                                                    target={targetMenu}
                                                    onHide={() => setOpenMenu(false)}
                                                    placement="bottom-start"
                                                    rootClose={true}
                                                >
                                                    <Popover id={`popover-containd1${i}`} style={{ width: 345, maxWidth: 345, display: menu === 'less_than_35' ? 'block' : 'none', borderRadius: 2, zIndex: 9999999, border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)' }}>
                                                        <div style={{ maxHeight: 350, overflow: 'auto' }}>

                                                            <Table responsive className='mt-3'>
                                                                <thead>
                                                                    <th>Student Name</th>
                                                                    <th>Student Id</th>
                                                                    <th>Student email</th>
                                                                </thead>
                                                                <tbody>
                                                                    {stuLoading ?
                                                                        'Loading' :
                                                                        list && list?.map(el => {
                                                                            return (
                                                                                <tr>
                                                                                    <td className='text-left'>{el.name}</td>
                                                                                    <td>{el.id}</td>
                                                                                    <td>{el.email}</td>
                                                                                </tr>
                                                                            )
                                                                        })

                                                                    }

                                                                </tbody>

                                                            </Table>
                                                        </div>
                                                    </Popover>
                                                </Overlay>
                                                <div className={el.less_than_35 === 0 ? '' : 'deco'} onClick={(e) => el.less_than_35 === 0 ? '' : openMenus(e, 'less_than_35', el.users_less_than_35)}>
                                                    {el.less_than_35}
                                                </div>
                                            </td>
                                            <td>
                                                <Overlay
                                                    show={openMenu}
                                                    target={targetMenu}
                                                    onHide={() => setOpenMenu(false)}
                                                    placement="bottom-start"
                                                    rootClose={true}
                                                >
                                                    <Popover id={`popover-containd2${i + 5}`} style={{ width: 345, maxWidth: 345, display: menu === 'range_35_to_49' ? 'block' : 'none', borderRadius: 2, zIndex: 9999999, border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)' }}>
                                                        <div style={{ maxHeight: 350, overflow: 'auto' }}>
                                                            <Table responsive className='mt-3'>
                                                                <thead>
                                                                    <th>Student Name</th>
                                                                    <th>Student Id</th>
                                                                    <th>Student email</th>
                                                                </thead>
                                                                <tbody>
                                                                    {stuLoading ?
                                                                        'Loading' :
                                                                        list && list?.map(el => {
                                                                            return (
                                                                                <tr>
                                                                                    <td className='text-left'>{el.name}</td>
                                                                                    <td>{el.id}</td>
                                                                                    <td>{el.email}</td>
                                                                                </tr>
                                                                            )
                                                                        })

                                                                    }

                                                                </tbody>

                                                            </Table>
                                                        </div>
                                                    </Popover>
                                                </Overlay>
                                                <div className={el.range_35_to_49 === 0 ? '' : 'deco'} onClick={(e) => el.range_35_to_49 === 0 ? '' : openMenus(e, 'range_35_to_49', el.users_range_35_to_49)}>
                                                    {el.range_35_to_49}
                                                </div>

                                            </td>
                                            <td>
                                                <Overlay
                                                    show={openMenu}
                                                    target={targetMenu}
                                                    onHide={() => setOpenMenu(false)}
                                                    placement="bottom-start"
                                                    rootClose={true}
                                                >
                                                    <Popover id={`popover-containd3${i + 4}`} style={{ width: 345, maxWidth: 345, display: menu === 'range_50_to_89' ? 'block' : 'none', borderRadius: 2, zIndex: 9999999, border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)' }}>
                                                        <div style={{ maxHeight: 350, overflow: 'auto' }}>
                                                            <Table responsive className='mt-3'>
                                                                <thead>
                                                                    <th>Student Name</th>
                                                                    <th>Student Id</th>
                                                                    <th>Student email</th>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        stuLoading ?
                                                                            'Loading' :
                                                                            list && list?.map(el => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td className='text-left'>{el.name}</td>
                                                                                        <td>{el.id}</td>
                                                                                        <td>{el.email}</td>
                                                                                    </tr>
                                                                                )
                                                                            })

                                                                    }

                                                                </tbody>

                                                            </Table>
                                                        </div>
                                                    </Popover>
                                                </Overlay>
                                                <div className={el.range_50_to_89 === 0 ? '' : 'deco'} onClick={(e) => el.range_50_to_89 === 0 ? '' : openMenus(e, 'range_50_to_89', el.users_range_50_to_89)}>
                                                    {el.range_50_to_89}
                                                </div>

                                            </td>
                                            <td>
                                                <Overlay
                                                    show={openMenu}
                                                    target={targetMenu}
                                                    onHide={() => setOpenMenu(false)}
                                                    placement="bottom-start"
                                                    rootClose={true}
                                                >
                                                    <Popover id={`popover-containd4${i + 7}`} style={{ width: 345, maxWidth: 345, display: menu === 'range_90_to_100' ? 'block' : 'none', borderRadius: 2, zIndex: 9999999, border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)' }}>

                                                        <div style={{ maxHeight: 350, overflow: 'auto' }}>
                                                            <Table responsive className='mt-3'>
                                                                <thead>
                                                                    <th>Student Name</th>
                                                                    <th>Student Id</th>
                                                                    <th>Student email</th>
                                                                </thead>
                                                                <tbody>
                                                                    {stuLoading ?
                                                                        'Loading' :
                                                                        list && list?.map(el => {
                                                                            return (
                                                                                <tr>
                                                                                    <td className='text-left'>{el.name}</td>
                                                                                    <td>{el.id}</td>
                                                                                    <td>{el.email}</td>
                                                                                </tr>
                                                                            )
                                                                        })

                                                                    }

                                                                </tbody>

                                                            </Table>
                                                        </div>
                                                    </Popover>
                                                </Overlay>
                                                <div className={el.range_90_to_100 === 0 ? '' : 'deco'} onClick={(e) => el.range_90_to_100 === 0 ? '' : openMenus(e, 'range_90_to_100', el.users_range_90_to_100)}>
                                                    {el.range_90_to_100}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })

                                }

                            </tbody>

                        </Table>
                    </div>
                </Modal.Body>
            </Modal>



            {
                isHistory ?
                    (activeTab === 'test' ?
                        <HistoryPopup
                            testHistory={testHistory}
                            closePopup={() => setIsHistory(false)}
                            ishistoryLoading={ishLoading}
                            type='test-history'
                        /> :
                        <HistoryPopup
                            scoreHistory={scoreHistory}
                            closePopup={() => setIsHistory(false)}
                            fetchData={fetchData}
                            ishistoryLoading={props.ishistoryLoading}
                            type={'score'}
                        />)
                    : null
            }
            <Modal
                show={isOpenFilter}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"update-modal1"}
            >
                <Modal.Body style={{ padding: '22px 40px 22px 40px' }}>
                    <div>
                        <label>Class</label>
                        <div className='mt-2'>
                            <Select
                                components={{ DropdownIndicator }}
                                options={props.classes}
                                onChange={selectClass}
                                placeholder='Select Class'
                                value={cls}
                            />
                        </div>
                    </div>
                    <div>
                        <label>Section</label>
                        <div className='mt-2'>
                            <Select
                                components={{ DropdownIndicator }}
                                options={props.sectionOfClass}
                                onChange={selectSection}
                                placeholder='Select Section'
                                value={section}
                            />
                        </div>
                    </div>
                    <div className='save-btn'>
                        <button onClick={() => applyFilter()}>Apply</button>
                        <button onClick={() => setIsOpenFilter(false)}>Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>

        </div >
    )
}


